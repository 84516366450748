import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '../components/layout'

const AboutPage = () => {
    return (
        <Layout pageTitle="About">
            <p>Hi there! I'm the proud creator of this site, which I built with Gatsby.</p>
            <StaticImage alt="Clifford" src="../images/E1oMV3QVgAIr1NT.webp">

            </StaticImage>
        </Layout>
    )
}


export default AboutPage;
